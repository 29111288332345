<template>
  <div id="app" class="container">
    <h1>Live Online Users</h1>
    <div v-if="loading">Loading...</div>
    <div v-else-if="connectionLost" class="error">Connection Lost</div>
    <div v-else class="counter">{{ onlineUsers }}</div>
  </div>
</template>

<script>

import { io } from "socket.io-client";

export default {
  data() {
    return {
      onlineUsers: null,
      sessionId: null,
      socket: null,
      loading: true,
      connectionLost: false,
      isTabActive: true, // Track if the tab is active
    };
  },
  methods: {
    getOrGenerateSessionId() {
      let sessionId = localStorage.getItem("sessionId");
      if (!sessionId) {
        sessionId = Math.random().toString(36).substr(2, 9);
        localStorage.setItem("sessionId", sessionId);
      }
      return sessionId;
    },
    syncOnlineUserCount(count) {
      this.onlineUsers = count;
      this.loading = false;
      this.connectionLost = false;
    },
    handleVisibilityChange() {
      const isVisible = !document.hidden;
      if (isVisible) {
        this.isTabActive = true;
        this.socket.emit("tabVisible", this.sessionId);
      } else {
        this.isTabActive = false;
        this.socket.emit("tabHidden", this.sessionId);
      }
    },
  },
  mounted() {
    this.sessionId = this.getOrGenerateSessionId();

    // this.socket = io("http://localhost:3000");
    this.socket = io("https://www.derapiplatz.com");

    this.socket.emit("register", this.sessionId);

    this.socket.on("onlineUsers", (count) => {
      this.syncOnlineUserCount(count);
    });

    let alreadyRegistered = true;

    this.socket.on("connect", () => {
      this.connectionLost = false;
      this.loading = false;

      if (!alreadyRegistered) {
        this.socket.emit("register", this.sessionId);
        alreadyRegistered = true;
      }
    });

    this.socket.on("disconnect", () => {
      this.connectionLost = true;
      this.loading = false;
      alreadyRegistered = false;
    });

    // this.socket.emit("setSessionId", this.sessionId);

    // tab close or refresh
    window.addEventListener("beforeunload", () => {
      this.socket.emit("userDisconnecting", this.sessionId);
    });

    // Handle visibility change
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeUnmount() {
    if (this.socket) {
      // this.socket.emit("userDisconnecting", this.sessionId); // Notify the server before disconnecting
      this.socket.disconnect(); // socket clean up before unmount!
    }
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
};
</script>

<style>
.container {
  text-align: center;
  margin-top: 100px;
  font-family: Arial, sans-serif;
}
.counter {
  font-size: 64px;
  font-weight: bold;
  margin-top: 20px;
}
.error {
  font-size: 24px;
  font-weight: bold;
  color: red;
  margin-top: 20px;
}
</style>
